/* .menu img{
    width: 100%;
    max-width: 45px;
    max-height: 45px;
    border-radius: 15px;
}

.menu {
    overflow: scroll;
    height: 100vh;
}

.menu::-webkit-scrollbar {
    display: none;
  }
  
  Hide scrollbar for IE, Edge and Firefox
  .menu {
    -ms-overflow-style: none;  IE and Edge
    scrollbar-width: none;  Firefox
  }


.imgMain{
    border-radius: 3px;
}

.close-button {
    float: right;
    color: white;
    margin-right: -15px;
    padding-top: 13px;
    cursor: pointer;
}

@media (min-width: 1100px) {
    .close-button {
        visibility: hidden;
    }
}

.MainNavbar{
    margin-top: 10px;
    overflow: auto;
}
.MainNavbar ul li a{
    cursor: pointer;
}

.mainNavUserInfo{
    display: flex;
    flex-direction: row;
    padding-left: 30px;
    padding-bottom: 10px;
}

.userImg{
    border-radius: 50% !important;
    background-color: #e1e2e3;
    border: 1px solid #e1e2e3;
    padding: 2px;
    width: 100%;
    max-width: 60px !important;
    max-height: 60px !important;
}

.userInfotext{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.logoHeading {
    font-size: 16px;
    color: white;
    padding-left: 5px;
}

.menu nav ul li a {
    display: block;
    font-weight: 700;
    padding: 16px 30px;
    text-decoration: none;
}

.img {
    background: var(--MainColor);
    height: 65px;
    justify-content: center;
    padding: 9px 25px;
    position: sticky;
    text-align: center;
    top: 0;
}


.icon {
    padding-right: 5px;
}

.menu nav ul li:hover {
    border-left: 2px solid #004AAD;
}


  a.link {
    padding: 16px 0px 2px 37px !important;
}

.sidebararrowposition {
    position: absolute;
    right: 15px;
    top: 226px;
} */

.side-menu {
  width: 250px !important;
}
.side__panel {
  width: 57px;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 4rem;
  z-index: 9;
}
.side__panel::-webkit-scrollbar {
  display: none;
}
.side__panel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.side__panel .side-panel-list ul li a {
  color: #fff;
  font-size: 16px;
}
.side__panel .side-panel-list ul li .uk-accordion-content {
  margin-top: 0;
  padding-left: 4.3rem;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
}
.side__panel .side-panel-list ul li .uk-accordion-content.show {
  height: 90px;
  padding: 0.1rem;
  padding-left: 4.3rem;
  opacity: 1;
  visibility: visible;
  /* transition: all 1s ease-in-out; */
}
.side__panel .side-panel-list ul li .uk-accordion-content.hide {
  height: 0;
  opacity: 0;
  /* transition: all 1s ease-in-out; */
  visibility: hidden;
}
.side__panel .side-panel-list ul li .uk-accordion-content .dropdown-list {
  font-size: 12px;
  display: block;
  padding: 0.3rem 0;
  transition: all 0.3s ease-in-out;
}
.side__panel .side-panel-list ul li .uk-accordion-content .dropdown-list:hover {
  letter-spacing: 1px;
}
.side__panel .side-panel-list ul .list-title {
  padding: 0.7rem;
  display: flex;
  align-items: center;
}
.side__panel .side-panel-list ul .list-title:hover {
  background: #3b7dd8;
}
.side__panel .side-panel-list ul .list-title i {
  transition: all 0.3s ease-in-out;
}
.side__panel .side-panel-list ul .list-title:hover i {
  font-size: 1.2rem;
}
.side__panel .side-panel-list ul .list-title .list {
  position: absolute;
  display: inline-block;
  width: 100%;
  margin-left: 1.2rem !important;
  margin-top: -0.7rem !important;
  font-size: 12px;
}
.side__panel .side-panel-list ul .list-title .dropdown-icon {
  position: absolute;
  right: 35%;
  transition: all 0.3s ease-in-out;
}
.side__panel .side-panel-list ul .list-title img {
  height: 30px;
}
.side__panel .side-panel-list ul .list-title::before {
  display: none;
}
.side__panel .side-panel-list ul .list-title > span {
  transition: transform 0.5s;
  margin-left: 0.3rem;
}
.side__panel:hover {
  width: 250px;
}
.active {
  background: #3b7dd8;
}
