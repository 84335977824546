/* .UpperNav{
    position: sticky;
    top: 0;
    z-index: 35;
}
.wrapper{
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);

}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--MainColor);    
    padding: 9px 25px;
    color:white;
    height: 65px;
    position: relative;
}

.navbar__right{   
    display: flex;
   flex-direction: row;
   
}
.navbar__right img{
    width: 45px;
    height: 45px;
    border-radius: 15px;
    object-fit: contain;
}
.navbar__left{
    display: flex;
    flex-direction: row;
}

.mainNavUserInfo{
    display: flex;
    flex-direction: row;
    padding-left: 30px;
}

.upperuserImg{
    border-radius: 50% !important;
    background-color: #e1e2e3;
    border: 1px solid #e1e2e3;
    width: 100%;
    height: 100%;
    max-width: 45px !important;
    max-height: 50px !important;
}

.col-lg-12.col-md-12.col-sm-12.UpperNav {
    padding: 0;
}

.toogle{
    border: none;
    background: var(--MainColor);
    color: white;
    transition: 0.5s;
}

.dropLogomenu{
    background: white;
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 45;
    margin-top: 5px;
    border: 1px solid #cccccc;
    box-shadow: 0 0px 1px rgb(0 0 0 / 18%);
    background-clip: padding-box;
  }
.dropLogomenu ul{
    padding:10px 40px;

    display: inline-block;
    margin: 0;
}
.dropLogomenu ul li{
   display: block;
}

.dropLogomenu ul li a:hover{
  color:var(--textBlack);
   
}

.dropLogomenu a{
    text-decoration: none;
    display: block;

}


<------------------ SEARCHBAR---------------->

.searchbar{
    position: absolute;
    left: 0;
    top: 65px;
    width: 100%;
}
.searchBarPosition{
    position: relative;
    width: 100%;
}
.seachradius{
    border-radius: 0 !important;
    height: 57px;
}
.searchbarabsolute{
    position: absolute;
    right: 50px;
    top:8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchcloseAbsolute{
    position: absolute;
    right: 15px;
    top:13px;
}
.searchBarclose{
    color:#c1c2c3;
    font-size: 25px;
} */

.nav {
  box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.25);
  padding-bottom: 0.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9; 
  width: 100%;
}
.nav .logo {
  padding-left: 5rem;
  /* margin: 1rem 0; */
}
.nav .logo img {
  height: 60px;
  object-fit: cover;
}
.nav h3 {
  font-weight: bold;
  margin: 0;
  font-size: 18px;
}
.nav h3 span {
  color: #0049ae;
}
.nav p {
  margin: 0;
  font-size: 12px;
}
.nav .address {
  font-size: 12px;
}
.nav span {
  color: #000;
  margin-right: 1rem;
}
.nav .notification {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}
.nav .notification .uk-badge {
  background: #0049ae;
  position: absolute;
  top: -50%;
  left: 15px;
}
.nav .user {
  cursor: pointer;
}
.nav .user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-left: 1rem;
  -o-object-fit: cover;
  object-fit: cover;
}
.nav .form-check-input {
  width: 2.3rem;
  margin-left: -2.5em;
  background-image: url(../images/switch1.svg) !important;
  background-position: left center;
  border-radius: 2em !important;
  transition: background-position 0.15s ease-in-out;
  height: 1.3rem;
  margin-top: 0.25em;
  margin-right: 0.5rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgb(0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.nav .form-check-input:checked {
  background-position: right center;
  background-image: url(../images/switch.svg) !important;
  background-color: #0049ae;
  border-color: #0049ae;
}

.dropLogomenu {
  background: white;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 45;
  margin-top: 5px;
  border: 1px solid #cccccc;
  box-shadow: 0 0px 1px rgb(0 0 0 / 18%);
  background-clip: padding-box;
}

.dropLogomenu ul {
  padding: 10px 40px;

  display: inline-block;
  margin: 0;
}
.dropLogomenu ul li {
  display: block;
}

.dropLogomenu ul li a:hover {
  color: var(--textBlack);
}

.dropLogomenu a {
  text-decoration: none;
  display: block;
}

#mob-nav {
  position: absolute;
  right: 0;
  padding: 1rem 2rem;
  /* z-index: 1000; */
  box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.25);
  border-radius: 9px;
  top: 60px;
}
