.BoxUpload {
  display: grid;
  margin-top: 20px;
  place-items: center;
  border: 1px solid #aab9d3;
  /* padding: 36px 48px; */
  position: relative;
  height: 150px;
  width: 28%;
  background: #fbfbff;
  border-radius: 5px;
}
.BoxUpload > .image-upload {
  display: flex;
  flex-wrap: wrap;
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > label :hover {
  opacity: 0.8;
}
.image-upload > input {
  display: none;
}

.ImagePreview {
  position: relative;
}
/* cursor: pointer; */
#uploaded-image {
  height: 150px;
  width: 100%;
  object-fit: fill;
  border-radius: 5px;
}
.close-icon {
  background: #000;
  border-radius: 5px;
  opacity: 0.7;
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 5px;
  cursor: pointer;
  z-index: 0;
}
.close-icon:hover {
  opacity: 1;
}

.btn {
  padding: 0.15rem 0.65rem;
}

.imgPrv-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: translate(-50%, -50%);
  /* border: 1px solid green; */
}

@media (max-width: 767px) {
  .imgPrv-popup-inner {
    width: 90vw !important;
  }
}

/* @media (min-width: 1600px) {
  .imgPrv-popup {
    right: -43px;
    bottom: -189px;
  }
} */

.imgPrv-popup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50vw;
  height: 80vh;
  left: 50%;
  top: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}

.ImgPreview {
  /* border: 1px solid red; */
  position: relative;
  /* width: 150px; */
  height: 100%;
  width: 100%;
  /* margin: 10px; */
  display: flex;
}

#prv-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.imgPrv-close-icon {
  position: absolute;
  color: white;
  background-color: black;
  /* border: 1px solid red; */
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 5;
  opacity: 0.7;
}

.imgPrv-close-icon:hover {
  opacity: 1;
}
