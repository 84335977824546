@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,800;1,600;1,700&display=swap");
* {
  font-family: "Open Sans", sans-serif;
  padding: 0;
}
body {
  font-size: 13px;
}

p {
  color: #000;
  font-size: 12px;
}

h1,
h2,
h3,
h5,
h4,
h6 {
  color: #000;
}

a:hover,
a:active {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

:root {
  --MainColor: #004aad;
  --MainTextColor: #656565;
  --textBlack: #232224;
  --Maingray: #f5f8fa;
  --ActiveColor: #eef3f7;
}

ul li a {
  color: #727399;
}

ul {
  list-style: none;
  padding: 0;
}
.side__content {
  padding-right: 40px;
  padding-left: 100px;
  margin-top: 4rem;
  transition: all 0.3s ease-in-out;
  min-height: calc(100vh - 4.1rem);
  padding-top: 1rem;
}

.popUpHeader {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}
.popUpHeader .fontHeader {
  color: White;
}
.insidePopup {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  max-width: 740px;
  overflow: hidden;
  /* overflow: scroll; */
  /* height: 85vh; */
}

.popUP {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
  z-index: 39;
  /* height:100%; */
}

.displayPopup {
  display: none;
}

/*Pop up inside pop up */

.inside--event {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  /* max-width: 650px; */
  overflow: hidden;
  /* max-height: 527px; */
  /* overflow: scroll; */
  height: 100vh;
}

/* @media screen and (min-width: 1516px) {
  .inside--event {
    top: 42%;
  }
}
@media screen and (min-width: 1706px) {
  .inside--event {
    top: 37%;
  }
}
@media screen and (min-width: 2048px) {
  .inside--event {
    top: 31%;
  }
} */

.inside--places {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  /* max-width: 650px; */
  overflow: hidden;
  /* max-height: 527px; */
  /* overflow: scroll; */
  height: 100%;
}

.eventPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
  z-index: 45;
}

.displaypop {
  display: none;
}

/*Display popup ends */

.popUP::-webkit-scrollbar {
  display: none;
}

.outlet {
  height: 500px;
  overflow: auto;
  padding-top: 10px;
}

.outlet::-webkit-scrollbar {
  display: thin;
}

.popUpHeaderText {
  font-size: 18px;
  padding-left: 5px;
}

.popUpHeader {
  padding: 10px;
  background: var(--MainColor);
  color: White;
}

.form-group > input[type="text"],
.form-group > input[type="date"],
.form-group > input[type="time"] {
  font-size: 12px;
}

.popUP.displayPopup.container-fluid.col-lg-12.col-md-12.col-sm-12.col-xs-12 {
  padding: 0px;
}

/** Basic form **/

.form-label {
  font-size: 12px;
  font-weight: bold;
}
input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 3;
}
input#text,
input#inputEmail4 {
  font-size: 12px;
}

.basicALertfooter {
  border-top: 2px solid var(--Maingray);
  position: sticky;
  bottom: 0px !important;
  margin: 0px !important;
  text-align: right;
  width: 100%;
  background: #ffffff;
  padding: 10px 0px;
}

.errormsg {
  font-size: 12px;
  color: red;
  margin: 0;
  margin-bottom: 0.7rem;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #0079bf;
  outline: none;
}

.btn-primary {
  background: var(--MainColor);
  border-color: var(--MainColor);
}

.table {
  margin-top: 1px;
}

/**** Css to select images in preview */

#img-preview {
  /* display: none;  */
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#img-preview img {
  width: 100%;
  height: 100%;
  /* display: block;    */

  /* height: 100%;
  width: 100%;
  object-fit: cover; */
}

/* [type="file"] {
  height: 0;  
  width: 0;
  overflow: hidden;
}
[type="file"] + label {
  font-family: sans-serif;
  background: #f44336;
  padding: 10px 30px;
  border: 2px solid #f44336;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
[type="file"] + label:hover {
  background-color: #fff;
  color: #f44336;
} */

/* <-------------- Date picker Css  -------------> */

._2xcMq {
  box-shadow: none !important;
  font-size: 12px !important;
  line-height: 1 !important;
  /* border: 1px solid #e1e2e3 !important; */
  width: 254px !important;
  top: -41px !important;
  right: -14px !important;
}
._1ImcB {
  background-color: var(--MainColor) !important;
}
._249_g {
  padding: 5px !important;
}
._FsLy_ {
  border-radius: 0% !important;
}
._3fmCl {
  border-width: 0 1px 1px 0 !important;
  color: var(--MainTextColor);
}
._bBYU7 {
  border: none !important;
}
._2aNF9 {
  font-size: 13px !important;
  outline: none !important;
  border: 1px solid #e1e2e3 !important;
}

._1oYOS {
  font-size: 12px !important;
  line-height: 1.5 !important;
}

input._1oYOS.form-control.form-control-sm.mb-1 {
  background: white !important;
  font-size: 12px !important;
}
._2aNF9:focus-visible {
  outline: none !important;
  border: 1px solid black !important;
}

/* <-------------- Date picker Css  End -------------> */

.pointer {
  cursor: pointer;
}

.table__button__wrapper {
  position: absolute;
  top: -40px;
  right: 235px;
}

.table__button__wrapper button {
  margin: 0 0.5rem 1rem 0.5rem;
  border: none;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.table__button__wrapper button:hover {
  transform: scale(1.1);
}

/*** CSS for Event Title Form **/

.add-title-button {
  margin-top: 26px;
}
@media (max-width: 768px) {
  .add-title-button {
    margin-top: -13px;
  }
}

.floatyy {
  text-align: right;
  margin-bottom: 10px;
}

.sup-col {
  color: red;
}

.mgmtImg {
  width: 52px;
  height: 52px;
  border: 1px solid var(--Maingray);
  border-radius: 8%;
  background-color: rgb(151, 151, 151);
  /* margin: 0 auto; */
}

.contentLogo img {
  width: 50px;
  height: 50px;
  border-radius: 8%;
}

.mgmtImgs {
  width: 50px;
  height: 50px;
  border: 1px solid var(--Maingray);
  border-radius: 50%;
  background-color: rgb(151, 151, 151);
  /* margin: 0 auto; */
}

.contentsLogo img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.checkbox-close {
  padding-left: 25px;
  margin-block-start: auto;
}

.form--group {
  margin-bottom: -0.5rem !important;
}

@media (min-width: 959.9px) {
  .dots-icon,
  #mob-nav,
  .ham-menu {
    display: none;
  }
  .padding {
    padding-left: 300px;
  }
  .none {
    display: none !important;
  }
}
@media (max-width: 959.9px) {
  .login__page {
    overflow: hidden;
  }
  .login__page .login-form-wrapper,
  .login__page .login-img-wrapper {
    width: 75%;
    margin: 0 auto;
    padding-right: 0 !important;
    padding-left: 30px !important;
  }
  .logo {
    width: 200px !important;
    margin-left: 2rem;
  }
  .side__panel {
    width: 0 !important;
  }
  .side__content {
    padding-left: 40px !important;
  }
  .dashboard-wrapper {
    width: 75%;
    margin: 0 auto;
  }
  .dashboard-wrapper .dasboard-wrap {
    padding: 0;
  }
  .total-employee .total-employee-box {
    margin-bottom: 3rem;
  }
  .total-employee::before {
    display: none;
  }
  .project-details,
  .today-absent {
    padding: 1rem !important;
  }
  nav {
    padding: 0.5rem 0 !important;
  }
  #large-nav {
    display: none;
  }
  .dots-icon {
    padding-right: 2rem;
    cursor: pointer;
  }
  .dots-icon .dots {
    transition: all 0.3s ease-in-out;
  }
  .dots-icon .dots:hover {
    color: #0049ae !important;
  }
  .mob-nav {
    display: none;
  }
  .ham-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    cursor: pointer;
  }
  .ham-menu .ham-icon {
    transition: all 0.3s ease-in-out;
  }
  .ham-menu .ham-icon:hover {
    color: #0049ae !important;
  }
  .ham-menu .closed-icon {
    transition: all 0.3s ease-in-out;
  }
  .ham-menu .closed-icon:hover {
    color: #ff4949 !important;
  }
}
@media (max-width: 639.9px) {
  p {
    font-size: 12px;
  }
  .card-title {
    font-size: 20px;
  }
  .login__page {
    padding-top: 2rem;
  }
  .login__page .login-form-wrapper,
  .login__page .login-img-wrapper {
    width: 100%;
  }
  .login__page .login__img .txt h1 {
    font-size: 35px !important;
  }
  .login__page .login__form {
    padding: 2rem;
  }
  .dashboard-wrapper {
    width: 100%;
  }
}

@media (max-width: 575.9px) {
  .table__button__wrapper {
    position: static !important;
  }
}

@media (max-width: 360.9px) {
  .login__page .login__form {
    padding: 1rem;
  }
  .login__page .login__form form .uk-button {
    width: 75%;
  }
  .side__content {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.form-group {
  margin-bottom: 0.3rem !important;
}
