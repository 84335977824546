.profile-info {
  margin: 10px;
  display: flex;
  align-items: center;
}
.profile-info h5 {
  font-weight: bold;
  margin: 0.2rem 0;
  padding-left: 10px;
}
.profile-info p {
  margin: 0.2rem 0;
  padding-left: 10px;
}
.profile-info .profileImg {
  width: 60px;
  height: 60px;
  border: 1px solid #f5f8fa;
  border-radius: 8%;
  background-color: rgb(151, 151, 151);
}
.profile-info .profileImg img {
  width: 100%;
  height: 100%;
}
.profilePopup {
  text-align: left;
}
.profilePopup {
  background-color: #f5f8fa;
}
.profilePopup ul {
  display: flex;
  padding-left: 0rem !important;
  overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.profilePopup ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profilePopup ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.profilePopup ul li {
  display: inline-block;
  padding: 15px 15px;
  font-weight: 600;
}
.profilePopup ul li:hover {
  color: #232224;
  cursor: pointer;
  border-bottom: 2px solid #004aad;
}
.profilePopup ul li.active {
  color: #004aad;
  background: #eef3f7;
  border-bottom: 2px solid #004aad;
}
.profilePopup ul {
  margin-bottom: 6px !important;
}
.user-profile .profile-wrap h5 {
  font-weight: bold;
  margin: 0.3rem 0;
}
.user-profile .profile-wrap p {
  margin: 0.3rem 0;
}
.user-profile .profile-border {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.wrapper {
  max-height: 342px;
  overflow-y: auto;
  overflow-x: hidden;
}
