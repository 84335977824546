/* .contentMainSection{
    padding:25px 30px 13px 30px !important;
} */

.fontHeader {
  font-size: 23px;
  color: #656565;
}
.main-content {
  border: 1px solid #e1e2e3;
  border-radius: 0.2rem;
  padding: 10px 15px;
  box-shadow: 0 0 0 #000;
  background: #fff;
}

.dashboard-search-field {
  display: flex;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 4px;
}

.col-lg-6.col-md-6.col-sm-6.p-2.Search {
  width: 100%;
  margin-top: 7px;

  outline: none;
  padding: 4px;
  border-color: 1px solid gray;
}
.Search input[type="text"] {
  padding: 5px;
  border-radius: 0.2rem;
  border: 1px solid #e6e6e6;
  position: relative;
  width: 100%;
}
.Search input[type="text"]:focus {
  outline: none;
  border: 1px solid #2684ff;
}

.btn-cmpy {
  background: var(--MainColor);
  color: white;
}

.btn-cmpy:hover {
  color: white;
}
.col-lg-2.col-md-6.col-sm-6.Search {
  padding: 4.5px;
}

/** CSS For Complain Table ***********/

.outer-wrapper {
  /* max-width: fit-content;   */
  /* max-height: fit-content;   */
  margin: 0 auto;
}
.table-wrapper {
  overflow-y: auto;
  overflow-x: scroll;
  /* height: fit-content; */
  margin: 10px 0px;
  padding-bottom: 20px;
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}
.table-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

table th,
table td {
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

table td {
  font-size: 12px;
  padding-left: 20px;
}
tbody td {
  font-size: 12px;
}
tr td:first-child {
  text-align: center;
}
.tableWidth {
  width: 300px;
}
.widthOfT {
  width: 162px;
}
.widthOfT1 {
  width: 130px;
}
.w1 {
  width: 247px;
}
.w2 {
  width: 131px;
}

.tableHead td {
  font-weight: 600;
  position: sticky;
  border-left: 1px solid #dee2e6 !important;
  font-size: 13px;
  z-index: 10;
  background: linear-gradient(#e2f0ff, #fff, #e2f0ff);
  /* color: #fff; */
}

.table td:last-child {
  border-right: 1px solid #dee2e6 !important;
}
/* .table-wrapper{
    position: relative;
} */
.table td,
.table th {
  padding: 0.55rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
/* .table-wrapper thead tr td {
  background: #fafbfc;
} */
/* .table-wrapper thead tr td:nth-child(1){ 
    z-index: 3;
     position: sticky; 
    left:0; 
    background: #fafbfc;
    width: 95px;
} */

/* .table-wrapper td:nth-child(1){
    z-index: 2;
    position: sticky;
    left:0;
    background: #fafbfc;
    width: 95px;
} */
/* .table-wrapper thead tr td:nth-child(2){
    width: 70px;
    z-index: 3;
    position: sticky;
    left:95px;
    width: 95px;
    background: #fafbfc;
} */
/* .table-wrapper th:nth-of-type(odd) {
  background: #fafbfc !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  background: #fafbfc !important;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  background: white;
} */
/* .table-wrapper td:nth-child(2){
    z-index: 2;
    position: sticky;
    left:95px;
    width: 95px;
    background: white;
 
} */
/* .table-wrapper thead tr td:last-child{
    position: sticky;
    right: 0;
    z-index: 3;
    width: 160px;    
    background: #fafbfc;
} */
/* .table-wrapper td:last-child{
    position: sticky; 
    right: 0;
    z-index: 2;
    background: white;
    width: 160px;
} */
/* .tc {
  text-align: center;
} */
.tl {
  text-align: left;
}
.editspan {
  /* background: var(--MainColor); */
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  color: #000;
  border: 1px solid #a9a9a9;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.rejectspan {
  /* background: var(--MainColor); */
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  color: #000;
  border: 1px solid #a9a9a9;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.agreespan {
  /* background: var(--MainColor); */
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  color: #000;
  border: 1px solid #a9a9a9;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.solvespan {
  /* background: var(--MainColor); */
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  color: #000;
  border: 1px solid #a9a9a9;
  margin-right: 0.5rem;
  transition: all 0.3s ease-in-out;
}
.editspan:hover,
.rejectspan:hover,
.agreespan:hover,
.solvespan:hover {
  transform: scale(1.1);
}
table {
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;
}

table thead tr td {
  /* position: sticky; */
  top: -1px;
  /* color: #656565  !important; */
  /* text-align: center; */
  font-weight: normal;
  font-size: 14px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}

/***************** CSS for complain POP UP *******/

.complainPopUp {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
  z-index: 39;
  height: 100%;
}

.complainInside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background: white;
  border-radius: 3px;
  overflow: hidden;
  max-width: 600px;
  /* overflow: scroll; */
  /* height: 500px; */
}

@media screen and (max-width: 625px) {
  .complainInside {
    width: 500px;
  }
}

@media screen and (max-width: 510px) {
  .complainInside {
    width: 400px;
  }
}

.complain-outlet {
  height: 440px;
  overflow: auto;
}

.complain-table {
  width: 100%;
}

/* Add complain modal ****/

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal--pop,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  z-index: 23;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.complainTopicPop {
  display: none;
}
.relationTopicPop {
  display: none;
}
.professionTopicPop {
  display: none;
}
.academicTopicPop {
  display: none;
}
.streetTopicPop {
  display: none;
}
.viewHelplinePop {
  display: none;
}
.ln-verition > input {
  display: none;
}
.displayPopups{
  display: none;
}
/* .complainTopicPops {
    display: none;
} */

.tl-left {
  padding-left: 20px;
}
.complainFormFooter {
  border-top: 2px solid var(--Maingray);
  position: sticky;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}
