.account-bar{
    margin-top: 28px;
}

.accountarrowposition {
    position: absolute;
    top: 41px;
    right: 15px;
}

.fa-print{
    font-size: 23px;
    opacity: 0.6;
}

.fa-print:hover{
    cursor: pointer;
    opacity: 1;
}

.account--date--picker {
    position: absolute;
    top: 41px;
    right: 6%;
    opacity: 0.8;
  }

  .account-outlet {
    height: 412px;
    overflow: auto;
    padding-top: 10px;
}