.noticePopUp {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
  z-index: 39;
  height: 100%;
}

.noticeInside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  max-width: 740px;
  overflow: hidden;
  /* overflow: scroll; */
  height: 500px; 
}
.notice-outlet2 {
  height: 470px;
  overflow: auto;
  padding-top: 1rem;
}
.notice-outlet {
  height: 450px;
  overflow: auto;
  padding-top: 1rem;
  /* margin-top: 1rem;
  padding-bottom: 1rem; */
}
.notice-outlet1 {
  height: 490px;
  overflow: auto;
  padding-top: 1rem;
}

.notificationFormFooter {
  border-top: 2px solid var(--Maingray);
  position: sticky;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  /* z-index:99; */
}

.notification-form-check {
  padding-top: 10px;
  font-size: 12px;
}

.notificationpositondrop {
  position: absolute;
  top: 14px;
  right: 16px;
}

.label-radio {
  height: 30px;
  width: 65px;
  border: 1px solid var(--MainColor);
  margin: auto;
  border-radius: 3px;
  position: relative;
  color: var(--MainColor);
  background-color: white;
  transition: 0.5s;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  background-color: var(--MainColor);
  color: #ffffff;
}

input[type="radio"] {
  -webkit-appearance: none;
}
.feature {
  position: relative;
  width: 65px;
  height: 30px;
  margin: 5px;
  float: left;
  border: 1px solid var(--MainColor);
  border-radius: 5px;
  box-sizing: border-box;
}

.feature div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.feature input {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked ~ div {
  background-color: var(--MainColor);
  color: #ffffff;
}

.do-flex {
  display: flex;
}

/* .checkbox:checked + label {
	background-color: var(--MainColor);
	color: #ffffff;
	
}

.solid-checkbox:checked + label {
	background-color: var(--MainColor);
	color: #ffffff;
	
}
 
 input[type="checkbox"]{
    -webkit-appearance: none;
} */

.notification-visibility {
  visibility: hidden;
}

.selectVisibility {
  visibility: hidden;
}

.notificationpositon {
  position: absolute;
  top: 14px;
  right: 15px;
}
.notificationpositondrop {
  position: absolute;
  top: 32px;
  right: 15px;
}
.notificationpositondrops {
  position: absolute;
  top: 175px;
  right: 22px;
}

/**** CSS to select search box *****/

.css-1pahdxg-control:hover {
  padding: 0;
  border: 3px solid #004aad;
  height: 31.5px !important;
  line-height: 31.5px;
  min-height: 0 !important;
}

.css-1s2u09g-control {
  height: 29.5px !important;
  line-height: 28.5px;
  margin-top: 1.1px;
  min-height: 0 !important;
}
.css-319lph-ValueContainer {
  padding: 0px !important;
  margin-left: 10px;
  margin-bottom: 5px;
}
.css-1okebmr-indicatorSeparator {
  margin-bottom: 7px !important;
  margin-top: 0px !important;
  padding-top: 0 !important;
}
.css-6j8wv5-Input {
  padding: 0 !important;
  margin: 0 !important;
  color: var(--MainTextColor) !important;
}
.css-b62m3t-container {
  z-index: 4;
}
.css-tlfecz-indicatorContainer {
  padding-top: 0 !important;
}
.css-1gtu0rj-indicatorContainer {
  padding-top: 0 !important;
}
.css-qc6sy-singleValue {
  color: var(--MainTextColor) !important;
  font-size: 12px !important;
}
.css-1s2u09g-control {
  border: none;
}
.css-1pndypt-Input {
  margin: 0 !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-26l3qy-menu {
  margin: 0 !important;
  padding-top: 0px !important;
}

/**** CSS to select search box end *****/
.places-outlet {
  height: 467px;
  overflow: auto;
  padding-top: 10px;
}

.chhPopUp {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
  z-index: 39;
  height: 100%;
}

.chhimekiPop {
  display: none;
}

.chhimekiPops {
  display: none;
}
