.managementFormFooter { 
        border-top: 2px solid var(--Maingray);
        position: absolute;
        bottom: 0px !important;
        margin: 0px !important;
        width: 100%;
        background: #ffffff;
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
    }

.displayPopups {
    display: none;
}
