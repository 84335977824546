.flexy{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding:10px !important;
  }
  
  .logoutbtn{
    padding: 10px;
    background-color: var(--MainColor);
    font-size: 13px;
    border-radius: 3px !important;
    color: white;
    font-weight: 400;
    cursor: pointer;
  }

  .cancelbtn{
      padding:10px;
      background-color: var(--Maingray);
      font-size: 13px;
      color: var(--textBlack);
      border-radius: 3px !important;
      cursor: pointer;
    font-weight: 400;
   
  }
  
  @media (min-width: 992px){
    .popup-inner-alert.container.col-lg-12.col-md-12.col-sm-12.col-xs-12 {
        padding: 0;
    }
  }
  .popup-inner-alert {
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    position: absolute;
    padding-bottom: 0!important;
    border-radius: 3px;
  }
  
  .alertclosebtn{
    border-radius: 0 !important;
    font-size: 30px;
    color: #fff;
    font-weight: 100;
    padding: 0;
    padding-bottom: 0 !important;
    border: 0;
  }
  
  @media only screen and (max-width: 1600px)
  {
  
  
  .popup-inner-alert {
    max-width:  725px; 
  }
  
  }
  
  
  @media only screen and (max-width: 1350px)
  {
  .popUP{
    overflow: scroll;
  }
  
  }
  @media only screen and (max-width: 960px)
  {
  
  .popup-inner-alert {
  padding:0;
  }
  
  }
  @media only screen and (max-width: 720px)
  {
  
  .popup-inner-alert {
    padding:0
  }
  
  
  }
  @media only screen and (max-width: 500px)
  {
  
  .popup-inner-alert {
    max-width:  380px; 
  }
  }
  
  @media only screen and (max-width: 480px)
  {
  
  .popup-inner-alert {
    max-width:  340px; 
  }
  
  
  }
  
  
  .popUp-alert-header{
    display: flex;
    background-color: var(--MainColor);
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    color: var(--textBlack);
    font-weight: 400;
    color: white;
    padding: 0 10px !important;
    align-items: center;
  }
  
  .alertText{
    font-size: 15px;
    color: var(--textBlack);
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid var(--Maingray);
  }

  .fade-in{
    opacity: 1;
    z-index: 999;
    transition: all 0.9s ease;
}

.fade-out{
    animation-name: fadeOut;
  animation-duration: 5s;
}
.fade-default{
    opacity: 0;
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;} 
 } 