.MuiStepper-root {
  padding: 13px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #0079bf !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #5096be !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 12px !important;
}
.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: bold !important;
  font-family: sans-serif;
  font-size: 13px;
}

.MuiStepLabel-label {
  font-family: sans-serif;
  font-size: 13px;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: bold !important;
  font-family: sans-serif;
  font-size: 13px;
}
.MuiSvgIcon-root {
  font-size: 30px !important;
}
.MuiStepConnector-alternativeLabel {
  top: 15px !important;
}

/* Css for select dropdown starts */

.css-b62m3t-container {
  border: none;
}
.css-1s2u09g-control {
  border: none !important;
  border-radius: 0.2rem !important;
  border: 1px solid #e6e6e6 !important;
}
.css-1pahdxg-control {
  border: 0 !important;
  border-width: 1px !important;
  padding: 0 !important;
}
.css-1pahdxg-control {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #2684ff !important;
  border-radius: 0.2rem !important;
  height: 31.5px !important;
  line-height: 31.5px;
  min-height: 0 !important;
  position: static !important;
  z-index: 0;
}
.css-b62m3t-container {
  position: static !important;
}
/* .css-1pf3zof-menu{
  position: absolute !important;
  z-index: 1 !important;
 top:-50px;
 margin: 0 !important;
 margin-top: -10px !important;
 padding: 0 !important;
} */
.pos {
  position: relative !important;
}
.css-26l3qy-menu {
  width: 100% !important;
  position: absolute !important;
  z-index: 1 !important;
  top: -50px;
  margin: 0 !important;
  margin-top: -10px !important;
  padding: 0 !important;
}

/* Css ends for select search dropdown */

.display-picture {
  display: grid;
  /* margin-top: 20px; */
  place-items: center;
  border: 1px solid #aab9d3;
  position: relative;
  height: 150px;
  width: 100%;
  background: #fbfbff;
  border-radius: 5px;
}

.upload--display--button {
  background-color: #0079bf;
  padding: 10px;
  color: white;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 3px;
}

.notificationFormFooterpermanent {
  border-top: 2px solid var(--Maingray);
  position: absolute;
  bottom: 10px;
  right: 5px;
  text-align: right;
  width: 100%;
  background: #ffffff;
  padding-top: 10px;
}

.form-stepper {
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 10px;
}

.other--date--picker {
  position: absolute;
  top: 36px;
  right: 6%;
  opacity: 0.6;
}

.memberFormFooter {
  border-top: 2px solid var(--Maingray);
  position: absolute;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  text-align: right;
}
.personalFormFooter {
  border-top: 2px solid var(--Maingray);
  position: sticky;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  text-align: right;
}
.Member--Footer {
  border-top: 2px solid var(--Maingray);
  position: sticky;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Member-Footer {
  border-top: 2px solid var(--Maingray);
  position: absolute;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tempFormFooter {
  border-top: 2px solid var(--Maingray);
  position: absolute;
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  text-align: right;
}

.member-outlet {
  height: 490px;
  overflow: auto;
}

.personal-outlet {
  height: 380px;
  overflow: auto;
  margin-top: 1rem;
}

.personal-outlet label {
  color: #000;
}

.form-group.col-md-4 {
  margin: 0;
}

.uk-flex-right {
  justify-content: end !important;
}

/*CSS of SElecte Search */
/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.viewMemberPop {
  display: none;
}

.insidePopup nav {
  background-color: var(--Maingray);
}
.insidePopup nav ul li {
  display: inline-block;
  padding: 15px 20px;
  font-weight: 700;
  color: #000;
}
.insidePopup nav ul li:hover {
  color: var(--textBlack);
  cursor: pointer;
  border-bottom: 2px solid var(--MainColor);
}
.insidePopup nav ul li.active {
  color: var(--MainColor);
  background: var(--ActiveColor);
  border-bottom: 2px solid var(--MainColor);
}
.insidePopup nav ul {
  margin-bottom: 6px !important;
}

.inside--fam {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  max-width: 740px;
  overflow: hidden;
}

.inside--prof {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: white;
  border-radius: 3px;
  max-width: 100%;
  /* overflow: hidden; */
  max-height: 100%;
  height: 100%;
}

.familyTopicPop {
  display: none;
}

.changePasswordFooter {
  border-top: 2px solid var(--Maingray);
  bottom: 0px !important;
  margin: 0px !important;
  width: 100%;
  background: #ffffff;
  padding: 10px 10px;
  text-align: right;
}
