/* #upper-nav{
    background: #FFFFFF;
    padding: 0;
    position:absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.side-navfixed {
    background: #f5f8fa;
    padding: 0;
    z-index: 2;
    height: 200vh;
    position:fixed;
    top:0;
    min-width: 250px;
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
    overflow: auto;
    transform: translateX(0px);
    transition: transform 0.3s;
}

.side-nav{
    background: #f5f8fa;
    padding: 0;
    z-index: 38;
    height: 100vh;
    position:absolute;
    left:0;
    top:0;
    max-width: 250px;
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
    transform: translateX(0px);
    transition: transform 0.5s;
}
.background--a{
    background: #f5f8fa;
    padding: 0;
    z-index: 35;
    height: 100vh;
    left:10;
    top:0;
    width: 100%;
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
    backdrop-filter: blur(1px);
    transform: translateX(0px);
    transition: transform 0.3s;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.togglewidthsmall{
    max-width: 100%;
}
.togglewidthlarge{
    width: calc(100% - 250px);
}

.alpha--a{
    padding: 0;
}


.hide{
    display: none;
    transition: all 3s ease-in-out;
}
.show{
    display: block;
    transition:all 3s ease-in-out;
}

 */
.alertMsg {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* .content__wrapper {
  margin-top: 5rem;
} */
